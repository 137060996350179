<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Asignación Presupuestal</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Liquidaciones</li>
                  <li class="breadcrumb-item active">
                    Asignación Presupuestal
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div
                class="card-header pt-2 pb-2"
                v-if="
                  $store.getters.can('tep.asignacionesPresupuestales.create')
                "
              >
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <!-- /.card-header -->
                <div class="row">
                  <div class="col-md-12">
                    <table
                      class="table table-bordered table-striped table-hover table-sm text-nowrap"
                    >
                      <thead>
                        <tr>
                          <!---Inicio Input Filtro Radicado---->
                          <th>#</th>
                          <th>
                            Sitio
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="sitio"
                              placeholder="Seleccione Sitio..."
                              label="nombre"
                              :options="listasForms.sitios"
                              @input="selectSitio()"
                            >
                            </v-select>
                          </th>
                          <th>
                            Operación
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="operacion"
                              placeholder="Seleccione Operación..."
                              label="descripcion"
                              class="form-control form-control-sm p-0"
                              :options="listasForms.operaciones"
                              @input="selectOperacion()"
                            >
                            </v-select>
                          </th>
                          <th>
                            Area
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="area"
                              placeholder="Seleccione Area..."
                              label="descripcion"
                              class="form-control form-control-sm p-0"
                              :options="listasForms.areas"
                              @input="selectArea()"
                            >
                            </v-select>
                          </th>
                          <!-- <th>Porcentaje</th> -->
                          <th style="text-align: center">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(facturacion, index) in facturacionesPorc"
                          :key="index"
                        >
                          <!-- :class="[validarTotal(facturacion.porcentajeTotal) ? color : '']" -->
                          <td>{{ facturacion.id }}</td>
                          <td v-if="facturacion.sitio">
                            {{ facturacion.sitio.nombre }}
                          </td>
                          <td v-else></td>
                          <td v-if="facturacion.nOperacion">
                            {{ facturacion.nOperacion }}
                          </td>
                          <td v-else></td>
                          <td>{{ facturacion.nArea }}</td>
                          <!-- <td class="text-center">
                            {{ facturacion.porcentajeTotal }}
                          </td> -->

                          <td class="text-right" style="width: 50px">
                            <div class="btn-group float-right">
                              <!-- Botón Editar -->
                              <button
                                v-if="
                                  $store.getters.can(
                                    'tep.asignacionesPresupuestales.edit'
                                  )
                                "
                                type="button"
                                class="btn bg-navy btn-sm"
                                @click="edit(facturacion)"
                              >
                                <i class="fas fa-edit"></i>
                              </button>

                              <button
                                v-if="
                                  $store.getters.can(
                                    'tep.asignacionesPresupuestales.delete'
                                  )
                                "
                                type="button"
                                class="btn btn-sm btn-danger"
                                @click="destroy(facturacion.id)"
                              >
                                <i class="fas fa-trash-alt"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="facturaciones.total">
                  <p>
                    Mostrando del
                    <b>{{ facturaciones.from }}</b> al
                    <b>{{ facturaciones.to }}</b> de un total:
                    <b>{{ facturaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger"
                      >No hay registros para mostrar</span
                    >
                  </p>
                </div>
                <pagination
                  :data="facturaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- <TepLiquidacionPlantilla ref="TepLiquidacionPlantilla" /> -->
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";

export default {
  //name: "   ",
  components: {
    pagination,
    vSelect,
    Loading,
  },

  data() {
    return {
      cargando: false,
      id: null,
      modalTitle: null,
      sitio: {},
      detalles: {},
      operacion: null,
      area: null,
      color: null,
      facturaciones: {},
      facturacionesPorc: [],
      filtros: {
        sitio_id: null,
        operacion: null,
        area: null,
      },

      listasForms: {
        sitios: [],
        operaciones: [],
        areas: [],
      },

      operaciones: [],
      areas: [],
    };
  },

  methods: {
    async getIndex(page = 1) {
      /* await this.getDetalles(); */
      await axios
        .get("/api/tep/asignacionesPresupuestales?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.facturaciones = response.data;
        });
      await this.porcentaje();
    },

    async porcentaje() {
      this.facturacionesPorc = null;
      this.facturaciones.data.forEach((facturacion) => {
        let porcentajeTotal = 0;
        this.detalles = facturacion.det_ceco_afe_facturacion;
        this.detalles.forEach((detalle) => {
          porcentajeTotal = porcentajeTotal + Number(detalle.porcentaje);
        });
        this.facturacionesPorc = this.facturaciones.data;
        facturacion.porcentajeTotal = porcentajeTotal;
      });
    },

    validarTotal(total) {
      if (total == 100) {
        this.color = "table-success";
      } else {
        this.color = "";
      }
      return this.color;
    },

    async buscarSitios() {
      let me = this;
      await axios
        .get("api/admin/sitios/lista", {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getOperaciones() {
      await axios.get("/api/lista/80").then((response) => {
        this.listasForms.operaciones = response.data;
      });
    },

    selectSitio() {
      this.filtros.sitio_id = null;
      if (this.sitio) {
        this.filtros.sitio_id = this.sitio.id;
      }
      this.getIndex();
    },

    async selectOperacion() {
      this.filtros.operacion = null;
      if (this.operacion) {
        this.filtros.operacion = this.operacion.numeracion;
      }
      await this.getIndex();
    },

    async selectArea() {
      this.filtros.area = null;
      if (this.area) {
        this.filtros.area = this.area.numeracion;
      }
      await this.getIndex();
    },

    async getAreas() {
      await axios.get("/api/lista/99").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    create() {
      return this.$router.push({
        name: "/Tep/TepAsignacionesPresupuestalesForm",
        params: { accion: "Crear", id: null },
      });
    },

    edit(liqui) {
      return this.$router.push({
        name: "/Tep/TepAsignacionesPresupuestalesForm",
        params: { accion: "Editar", data_edit: liqui, id: liqui.id },
      });
    },

    destroy(id) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios
            .delete(
              "/api/tep/asignacionesPresupuestalesDetalles/deleteFacturacionCecoAfe/" +
                id
            )
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getIndex();
    await this.buscarSitios();
    await this.getOperaciones();
    await this.getAreas();

    this.cargando = false;
  },
};
</script>
